// color names taken from https://flatuicolors.com

.cursor-pointer {
    cursor:pointer;
}

.packages {
  a {
    text-decoration: none;
  }
  label {
    border-color: #f1f1f1;
  }
  label.active {
    border: 3px solid #80CC28 !important;
  }
}

.toggleActive {
	background-color: grey;
	color: white;
}

.intake-form .form-control {
	border-radius: 2rem;
}

.loading {
  opacity:1
}


@-moz-keyframes fadein {
  from {opacity:0}
  to {opacity:1}
}
@-webkit-keyframes fadein {
  from {opacity:0}
  to {opacity:1}
}
@-o-keyframes fadein {
  from {opacity:0}
  to {opacity:1}
}
@keyframes fadein {
  from {opacity:0}
  to {opacity:1}
}

.spinner-border-sm {
  width: 1.5rem;
  height: 1.5rem;
  border-width: 0.35em;
}

.question-row {
  cursor: pointer;
}
.question-active {
  font-weight: 700;
}

.option-row {
  background-color: #f2f2f2;
  display: none;
}

.desc-row {
  background-color: #f2f2f2;
  display: none;
}