$font-family-sans-serif: "Roboto Condensed", sans-serif;
@font-face {
  font-family: "myfont";
  src: url("/assets/fonts/HelveticaNeue-Condensed.woff") format("woff");
}

@font-face {
  font-family: "quote";
  src: url("/assets/fonts/RobotoCondensed-Light.ttf") format("woff");
}

$orange: #f37321;
$dark: #242d33;

// titles

head1,
.head1 {
  font-family: "Roboto", sans-serif;
  font-size: 56px;
  line-height: 1.23;
}

h1,
.h1 {
  font-family: "Roboto", sans-serif;
  font-size: 40px;
}

h2,
.h2 {
  font-family: "Roboto", sans-serif;
  font-size: 32px;
}

h4,
.h4 {
  font-family: "Roboto", sans-serif;
  font-size: 24px;
}

h5,
.h5 {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
}

h6,
.h6 {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
}

.card-group {
  flex-flow: row wrap;
}

[role=button] {
  cursor: pointer;
}


.jumbotron {
  border-radius: 0;
}
.subhead-banner {
  background-image: url("/assets/img/zelfopwekken-home.jpg");
  background-size: cover;
  background-position: center center;
  height: 33vh;
  .banner-content {
    // padding-top: 20%;
    // padding-bottom: 20%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 40vh;
  }

  head1 span, .head1 span {
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    margin-left: 0;
    color: white;
    background-color: $dark;
    padding-left: 48px;
    padding-right: 15px;
  }

  @media (max-width: 768px) {
    head1, .head1 {
      font-size: 48px;
      padding-left: 0px;
      line-height: 1.23;
    }
  }

  @media (max-width: 479px) {
    head1, .head1 {
      font-size: 36px;
      padding-left: 0px;
      line-height: 1.23;
    }
    // height: 40vh;
    // .banner-content {
    //   height: 40vh;
    // }
  }
}

// .card-deck {
//   display: grid;
//   grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
//   grid-gap: 0.5rem;
// }

.head-banner-holland {
  @include banner-holland;
}


.dropdown-menu {
  font-family: "Roboto", sans-serif;
  background-color: white;
  font-size: 1em;
  line-height: 2;
  margin-top: 0px;
  border: none;
}

.border-3 {
  border-width: 3px !important;
}

.premium {
  background-image: url("/assets/img/premium.jpg");
  background-size: cover;
  margin-bottom: 0;
}

// Custimze page Hoe werkt het? (hoewerkthet.html)

.hwt-title {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 30px;
}

.hwt-body {
  font-family: Roboto, sans-serif;
  font-weight: 300;
  font-size: 18px;
}

// customize page referenties

// .referenties {
//   background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
//     url('/assets/img/premium.svg');
//   background-size: cover;
//   margin-bottom: 0;
// }
.referenties {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("/assets/img/premium.jpg");
  background-size: cover;
  margin-bottom: 0;
}

input[type="text"]:disabled {
  background-color: white;
  color: black;
  font-weight: bold;
}

jumbo-vragen {
  background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url("/assets/img/vragenheader.svg");
  background-size: cover;
  margin-bottom: 0;
}
.card-header.collapsed {
  background: #fff;
}
.card-header:not(.collapsed) {
  // background: $dark;
  background: #f8f8f8;
}

.card-footer:last-child {
  border-radius: 0;
}

.btn-lg {
  padding: 0.9rem 1.4rem 0.5rem 1.5rem;
}

.custom-btn {
  background: #4f636b;
  color: white;
  border-color: #4f636b;
  font-size: 16px;
  clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 0% 100%);
  padding-left: 64px;
  padding-top: 30px;
  padding-bottom: 16px;
  padding-right: 32px;

  &:hover {
    background: #666;
    color: #fff;
    clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
}
.steps-plan {
  img {
    background-color: #f37321;
    height: 100%;
    width: auto;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .card-img-top {
    width: 100%;
    height: 50px;
    object-fit: 100%;
  }
  .card-title {
    @media (max-width: 768px) {
      text-align: center;
    }
  }
  .card-text {
    font-size: 16px;
    font-weight: 300;
    @media (max-width: 768px) {
      font-size: 18px;
      text-align: center;
    }
  }
}

.fa-li {
  left: 1em;
  position: absolute;
  text-align: center;
  width: 2em;
  line-height: inherit;
}

.voordelen {
  .filter-blue {
    filter: invert(66%) sepia(13%) saturate(5364%) hue-rotate(177deg)
      brightness(89%) contrast(86%);
  }
  .card-img-top {
    width: 100%;
    height: 30px;
    object-fit: 80%;
  }
}



.btn-outline-light {
  @include button-outline-variant(white, #fff, darken($primary, 15%), white);
}

.svg-content path:hover,
:focus {
  fill: $dark;
}

.card-deck {
  @media (max-width: 768px) {
    display: block;
  }
}
.card-header-img-end {
  background-image: url("/assets/img/tri-left.png");
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 10%;
  @media (max-width: 768px) {
    background-size: 6%;
  }
  @media (max-width: 479px) {
    background-size: 4%;
  }
}
.card-header-img-start {
  background-image: url("/assets/img/tri-left.png");
  background-repeat: no-repeat;
  // background-attachment: fixed;
  background-position: left;
  background-size: 15%;
  @media (max-width: 1000px) {
    background-size: 15%;
  }
}
.card-header-img {
  background-image: url("/assets/img/tri-left.png"),
    url("/assets/img/tri-left.png");
  background-repeat: no-repeat, no-repeat;
  background-position: left, right;
  background-size: 15%, 33%;
  @media (max-width: 1000px) {
    background-size: 47%;
  }
}

.col-2dot4,
.col-sm-2dot4,
.col-md-2dot4,
.col-lg-2dot4,
.col-xl-2dot4 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.col-2dot4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}
@media (min-width: 479px) {
  .col-sm-2dot4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (min-width: 768px) {
  .col-md-2dot4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}
@media (min-width: 960px) {
  .col-lg-2dot4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}
@media (min-width: 1140px) {
  .col-xl-2dot4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}

// Whitelabel


.navbar-nav .nav-item .nav-link {
  // border-left: 1px solid #cccccc;
  height: 100%;
  padding-left: 24px;
  padding-right: 12px;
}

.navbar-brand {
  max-width: 300px;
}

footer {
  background-color: white;
  a,
  p {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
  }
}

.brand1 {
  background-color: #EC0A67;
}
.brandtext1 {
  color: #EC0A67;
}

.btn-brand1 { 
  color: #ffffff; 
  background-color: #EC0A67; 
  border-color: #EC0A67; 
} 
 
.btn-brand1:hover, 
.btn-brand1:focus, 
.btn-brand1:active, 
.btn-brand1.active, 
.open .dropdown-toggle.btn-brand1 { 
  color: #ffffff; 
  background-color: #CC0859; 
  border-color: #EC0A67; 
} 
 
.btn-brand1:active, 
.btn-brand1.active, 
.open .dropdown-toggle.btn-brand1 { 
  background-image: none; 
} 
 
.btn-brand1.disabled, 
.btn-brand1[disabled], 
fieldset[disabled] .btn-brand1, 
.btn-brand1.disabled:hover, 
.btn-brand1[disabled]:hover, 
fieldset[disabled] .btn-brand1:hover, 
.btn-brand1.disabled:focus, 
.btn-brand1[disabled]:focus, 
fieldset[disabled] .btn-brand1:focus, 
.btn-brand1.disabled:active, 
.btn-brand1[disabled]:active, 
fieldset[disabled] .btn-brand1:active, 
.btn-brand1.disabled.active, 
.btn-brand1[disabled].active, 
fieldset[disabled] .btn-brand1.active { 
  background-color: #EC0A67; 
  border-color: #EC0A67; 
} 
 
.btn-brand1 .badge { 
  color: #EC0A67; 
  background-color: #ffffff; 
}




.brand2 {
  background-color: #14B4F0;
}
.brandtext2 {
  color: #14B4F0;
}

.btn-brand2 { 
  color: #ffffff; 
  background-color: #14B4F0; 
  border-color: #14B4F0; 
} 
 
.btn-brand2:hover, 
.btn-brand2:focus, 
.btn-brand2:active, 
.btn-brand2.active, 
.open .dropdown-toggle.btn-brand2 { 
  color: #ffffff; 
  background-color: #0F93C4; 
  border-color: #14B4F0; 
} 
 
.btn-brand2:active, 
.btn-brand2.active, 
.open .dropdown-toggle.btn-brand2 { 
  background-image: none; 
} 
 
.btn-brand2.disabled, 
.btn-brand2[disabled], 
fieldset[disabled] .btn-brand2, 
.btn-brand2.disabled:hover, 
.btn-brand2[disabled]:hover, 
fieldset[disabled] .btn-brand2:hover, 
.btn-brand2.disabled:focus, 
.btn-brand2[disabled]:focus, 
fieldset[disabled] .btn-brand2:focus, 
.btn-brand2.disabled:active, 
.btn-brand2[disabled]:active, 
fieldset[disabled] .btn-brand2:active, 
.btn-brand2.disabled.active, 
.btn-brand2[disabled].active, 
fieldset[disabled] .btn-brand2.active { 
  background-color: #14B4F0; 
  border-color: #14B4F0; 
} 
 
.btn-brand2 .badge { 
  color: #14B4F0; 
  background-color: #ffffff; 
}



.brand3 {
  background-color: #F47415;
}
.brandtext3 {
  color: #F47415;
}

.btn-brand3 { 
  color: #ffffff; 
  background-color: #F47415; 
  border-color: #F47415; 
} 
 
.btn-brand3:hover, 
.btn-brand3:focus, 
.btn-brand3:active, 
.btn-brand3.active, 
.open .dropdown-toggle.btn-brand3 { 
  color: #ffffff; 
  background-color: #D56512; 
  border-color: #F47415; 
} 
 
.btn-brand3:active, 
.btn-brand3.active, 
.open .dropdown-toggle.btn-brand3 { 
  background-image: none; 
} 
 
.btn-brand3.disabled, 
.btn-brand3[disabled], 
fieldset[disabled] .btn-brand3, 
.btn-brand3.disabled:hover, 
.btn-brand3[disabled]:hover, 
fieldset[disabled] .btn-brand3:hover, 
.btn-brand3.disabled:focus, 
.btn-brand3[disabled]:focus, 
fieldset[disabled] .btn-brand3:focus, 
.btn-brand3.disabled:active, 
.btn-brand3[disabled]:active, 
fieldset[disabled] .btn-brand3:active, 
.btn-brand3.disabled.active, 
.btn-brand3[disabled].active, 
fieldset[disabled] .btn-brand3.active { 
  background-color: #F47415; 
  border-color: #F47415; 
} 
 
.btn-brand3 .badge { 
  color: #F47415; 
  background-color: #ffffff; 
}



.brand4 {
  background-color: #80CC28;
}

.brandtext4 {
  color: #80CC28;
}

.btn-brand4 { 
  color: #ffffff; 
  background-color: #80CC28; 
  border-color: #80CC28; 
} 
 
.btn-brand4:hover, 
.btn-brand4:focus, 
.btn-brand4:active, 
.btn-brand4.active, 
.open .dropdown-toggle.btn-brand4 { 
  color: #ffffff; 
  background-color: #6BA824; 
  border-color: #80CC28; 
} 
 
.btn-brand4:active, 
.btn-brand4.active, 
.open .dropdown-toggle.btn-brand4 { 
  background-image: none; 
} 
 
.btn-brand4.disabled, 
.btn-brand4[disabled], 
fieldset[disabled] .btn-brand4, 
.btn-brand4.disabled:hover, 
.btn-brand4[disabled]:hover, 
fieldset[disabled] .btn-brand4:hover, 
.btn-brand4.disabled:focus, 
.btn-brand4[disabled]:focus, 
fieldset[disabled] .btn-brand4:focus, 
.btn-brand4.disabled:active, 
.btn-brand4[disabled]:active, 
fieldset[disabled] .btn-brand4:active, 
.btn-brand4.disabled.active, 
.btn-brand4[disabled].active, 
fieldset[disabled] .btn-brand4.active { 
  background-color: #80CC28; 
  border-color: #80CC28; 
} 
 
.btn-brand4 .badge { 
  color: #80CC28; 
  background-color: #ffffff; 
}





// Counter

/* .checkout-wrap {

  margin: 30px auto 100px;
  z-index: 0;
} */
ul.checkout-bar li {
  color: #ccc;
  font-size: 14px;
  position: relative;
  display: inline-block;
  margin: 30px 0px 4px 0px;
  padding: 0;
  text-align: center;
  width: 24%;
}
ul.checkout-bar li:before {
  // -webkit-box-shadow: inset 2px 2px 2px 0px rgba(0, 0, 0, 0.2);
  // box-shadow: inset 2px 2px 2px 0px rgba(0, 0, 0, 0.2);
  background: rgb(199, 199, 199);
  border: 2px solid rgb(255, 255, 255);
  border-radius: 50%;
  color: #fff;
  font-size: 24px;
  text-align: center;
  // text-shadow: 1px 1px rgba(0, 0, 0, 0.2);
  height: 40px;
  left:39%;
  line-height: 40px;
  position: absolute;
  top: -48px;
  width: 40px;
  z-index: 99;
}
ul.checkout-bar li.active {
  color: #14B4F0;
  font-weight: bold;
}
ul.checkout-bar li.active:before {
  background: #14B4F0;
}
ul.checkout-bar li.visited {
  color: #14B4F0;
  z-index: 99;
  background: none;
}
ul.checkout-bar li.visited:before {
  background: #14B4F0;
  z-index: 99;
}
ul.checkout-bar li:nth-child(1):before {
  content:"1";
}
ul.checkout-bar li:nth-child(2):before {
  content:"2";
}
ul.checkout-bar li:nth-child(3):before {
  content:"3";
}
ul.checkout-bar li:nth-child(4):before {
  content:"4";
}
ul.checkout-bar li:nth-child(5):before {
  content:"5";
}
ul.checkout-bar li:nth-child(6):before {
  content:"6";
}
ul.checkout-bar a {
  color: #ccc;
  font-size: 14px;
  text-decoration: none;
}
ul.checkout-bar li.active a {
  color: #14B4F0;
}
ul.checkout-bar li.visited a {
  color: #14B4F0;
}
.checkout-bar li.active:after {
  -webkit-animation: myanimation 3s 0;
  background-size: 35px 35px;
  background-color: #ddd;
  background-image: -webkit-linear-gradient(-45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(-45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent);
  // -webkit-box-shadow: inset 2px 2px 2px 0px rgba(0, 0, 0, 0.2);
  // box-shadow: inset 2px 2px 2px 0px rgba(0, 0, 0, 0.2);
  content:"";
  height: 8px;
  width: 100%;
  left: 50%;
  position: absolute;
  top: -30px;
  z-index: 0;
}
ul.checkout-bar {
  // -webkit-box-shadow: inset 2px 2px 2px 0px rgba(0, 0, 0, 0.2);
  // box-shadow: inset 2px 2px 2px 0px rgba(0, 0, 0, 0.2);
  background-size: 35px 35px;
  background-color: #EcEcEc;
  background-image: -webkit-linear-gradient(-45deg, rgba(255, 255, 255, 0.4) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0.4) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(-45deg, rgba(255, 255, 255, 0.4) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0.4) 75%, transparent 75%, transparent);
  border-radius: 15px;
  height: 8px;
  padding: 0;
  position: absolute;
  width: 95%;
}
ul.checkout-bar:before {
  background-size: 35px 35px;
  background-color: #14B4F0;
  background-image: -webkit-linear-gradient(-45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(-45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent);
  // -webkit-box-shadow: inset 2px 2px 2px 0px rgba(0, 0, 0, 0.2);
  // box-shadow: inset 2px 2px 2px 0px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  content:" ";
  height: 8px;
  left: 0;
  position: absolute;
  width: 14%;
}
ul.checkout-bar li.visited:after {
  background-size: 35px 35px;
  background-color: #14B4F0;
  background-image: -webkit-linear-gradient(-45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(-45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent);
  // -webkit-box-shadow: inset 2px 2px 2px 0px rgba(0, 0, 0, 0.2);
  // box-shadow: inset 2px 2px 2px 0px rgba(0, 0, 0, 0.2);
  content:"";
  height: 8px;
  left: 50%;
  position: absolute;
  top: -30px;
  width: 100%;
  z-index: 99;
}

.intake-control-checkbox {
	position: relative;
    margin-bottom: 0;
	vertical-align: top;
	&:before {
		position: absolute;
		top: 0.25rem;
		left: -1.5rem;
		display: block;
		width: 2rem;
		height: 2rem;
		pointer-events: none;
		content: "";
		background-color: #fff;
		border: #adb5bd solid 1px;
	}
	&:after {
		position: absolute;
		top: 0.25rem;
		left: -1.5rem;
		display: block;
		width: 2rem;
		height: 2rem;
		content: "";
		background: no-repeat 50% / 50% 50%;
	}
}

%visually-hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px; width: 1px;
  margin: -1px; padding: 0; border: 0;
}

.star-rating {

  &__star {
    display: inline-block;
    padding: 3px;
    vertical-align: middle;
    line-height: 1;
    font-size: 1.5em;
    color: #ABABAB;
    transition: color .2s ease-out;

    img {
      height: 40px;
    }

    &:hover {
      cursor: pointer;
    }

    &.is-selected {
      color: #FFD700;
    }

    &.is-disabled:hover {
      cursor: default;
    }
  }

  &__checkbox {
    @extend %visually-hidden;
  }
}


.title-input {
  button {
    background-color: #D9D9D9;
    border: none;
  }
  input[type="text"]:disabled {
    background-color: #F2F4F5;
    font-weight: 400;
  }
}

.tab-header {
  display: inline-block;
  padding: 10px 40px;
  cursor: pointer;
  color: grey;
  font-weight: 600;
  &.active-tab {
    background-color: #707070;
    color: white;
  }
}