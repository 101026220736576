$enable-responsive-font-sizes: true;

@mixin banner-holland {
  background-image: url('/assets/img/onzeinstallateurs.jpg');
  background-size: cover;
  background-position: center center;
  // height: 80vh;
  .banner-content-holland {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  h1 span {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 48px;
    font-weight: bold;
    margin-left: 0;
    color: white;
    background-color: $dark;
    padding-left: 30px;
    padding-right: 15px;
  }

  @media (max-width: 479px) {
    h1 span {
      font-size: 25px;
      padding-left: 20px;
    }
  }
}

@mixin button-outline-variant(
  $color,
  $color-hover: color-yiq($color),
  $active-background: $color,
  $active-border: $color
) {
  color: $color;
  background-color: transparent;
  background-image: none;
  border-color: $color;

  &:hover {
    color: $color-hover;
    background-color: $active-background;
    border-color: $active-border;
  }

  &:focus,
  &.focus {
    box-shadow: 0 0 0 $btn-focus-width rgba($color, 0.5);
  }

  &.disabled,
  &:disabled {
    color: $color;
    background-color: transparent;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: color-yiq($active-background);
    background-color: $active-background;
    border-color: $active-border;

    &:focus {
      // Avoid using mixin so we can pass custom focus shadow properly
      @if $enable-shadows and $btn-active-box-shadow != none {
        box-shadow: $btn-active-box-shadow,
          0 0 0 $btn-focus-width rgba($color, 0.5);
      } @else {
        box-shadow: 0 0 0 $btn-focus-width rgba($color, 0.5);
      }
    }
  }
}
