// Bootstrap overrides variables
// $theme-colors: (
//   'secondary': rgba(218, 63, 49, 0.911)
// );
// $bg-dark: #242d33;
$colorbut: yellow;

$theme-colors: (
  'primary': #489bd4,
  'dark': #242d33,
  'danger': #f37321,
  'blue-gray': #4f636b,
  'lightjade': #f7f9fa,
  'custcolor': $colorbut
);
